import { useConfigContext } from "@services/context/ConfigurationContext";
import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiTitle,
  VuiTextColor,
} from "@adventera/vectara-ui";
import "./appHeader.scss";
import { useNavigate } from 'react-router-dom';


export const AppHeader = () => {
  const { searchTitlePre, searchLinks } = useConfigContext();
  const navigate = useNavigate();

  function goToHomePage(){
    navigate('/')
  }

  return (
    <div className="appHeader" id="appHeader">
      <VuiFlexContainer justifyContent="spaceBetween" alignItems="center">
        <VuiFlexItem grow={1}>
          <VuiFlexContainer alignItems="center" justifyContent="spaceBetween">
            <VuiFlexItem grow={0}>
              <button onClick={goToHomePage}>
                <img src="img/numy_analytics_without_bg.png" alt="Numy logo" height="70" />
              </button>
            </VuiFlexItem>

            <VuiFlexItem grow={1}>
              <VuiTitle size="xs" align="left">
                <VuiTextColor color="subdued">
                  <p>
                    {searchTitlePre}{" "}
                    {
                      searchLinks!.map(((searchLink, idx) =>
                        <span key={idx.toString()}>
                          <a
                            href={searchLink.searchTitleUrl ?? ""}
                            target="_blank"
                          >
                            {searchLink.searchTitleInner}
                          </a>
                          {searchLink.searchTitlePost}
                        </span>
                      ))
                    }
                    {" "}

                  </p>
                </VuiTextColor>
              </VuiTitle>
            </VuiFlexItem>
          </VuiFlexContainer>
        </VuiFlexItem>

        <VuiFlexItem grow={false}>
          <VuiFlexContainer justifyContent="spaceBetween" alignItems="center">
          </VuiFlexContainer>
        </VuiFlexItem>
      </VuiFlexContainer>
    </div>
  );
};
